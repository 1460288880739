@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

$primaryLight: #eef4ed;
$primaryDark: #0b2545;
$secondaryDark: #8da9c4;
$secondaryLight: #13315c;
$ternary: #134074;

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $primaryLight;
  color: $primaryDark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-moz-selection {
  background-color: rgba(255, 99, 132, 0.4);
  color: white;
}
::selection {
  background-color: rgba(255, 99, 132, 0.4);
  color: white;
}
